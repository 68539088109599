// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()


// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports
import "bootstrap";

// from package.json and yarn add
// require("@fancyapps/fancybox")
// require("imagesloaded")
// require("is_js")
// require("isotope-layout")
// require("isotope-packery")
// require("jquery")
// require("jquery-countdown")
// require("jquery.mb.ytplayer")
// require("jtap")
// require("lightbox2")
// require("loaders.css")
// require("owl.carousel")
// require("plyr")
// require("popper.js")
// require("prismjs")
// require("progressbar.js")
// require("rellax")
// require("semantic-ui-accordion")
// require("semantic-ui-transition")
// require("/app/javascript/vendor/sparrow/stickykit.js")
// require("stickyfilljs")
// require("/app/javascript/vendor/sparrow/typed-text.js")

// // import GSAP files
// import {TweenMax, TweenLite, CustomEase, ScrollToPlugin, DrawSVGPlugin } from "../gsap/all";
// const plugins = [ ScrollToPlugin, CustomEase, DrawSVGPlugin ];

// Internal imports, e.g:
// import { initSelect2 } from '../components/init_select2';

document.addEventListener('turbolinks:load', () => {
  // Call your functions here, e.g:
  // initSelect2();

});


require("trix")
require("@rails/actiontext")
